import React from 'react'
import PropTypes from 'prop-types'
import './ContactUs.css';
import { Form } from "./Form";



function ContactUs(props) {

    return (
        <div className="contact-us-container">
            <div className="contact-us-title">{props.t('ContactUs')}</div>
            <Form {...props} />
        </div>
    )
}

ContactUs.propTypes = {
    t: PropTypes.func
}

export default ContactUs

