import React from 'react'
import './Vision.css'
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  CauseReadMoreAbout: {
    color: '#E39E95',
    fontFamily: 'amatic sc,cursive',
    fontSize: '40px',
    letterSpacing: '0.05em',
    textShadow: 'rgba(255, 255, 255, 0.6) 1px 1px 1px, rgba(0, 0, 0, 0.6) -1px -1px 1px',
    textAlign: 'center',
    lineHeight: 'normal'
  },
}));

function Vision(props) {

    const classes = useStyles();

    return (
        <div className="Background" >
            <div className="CauseMain" >{props.t('CauseMain')}</div>
            <div className="CauseTitle" >{props.t('CauseTitle')}</div>
            <div className="CauseText" >
                <div className="textContainer">{props.t('CauseText1')}</div>
                <div className="textContainer">{props.t('CauseText2')}</div>
            </div>
            <div className="CauseReadMoreAbout" >
                <Link onClick={()=> props.toTab('/about')} className={classes.CauseReadMoreAbout}>{props.t('CauseReadMoreAbout')}</Link>
            </div>
            <div className="CauseNotice" ><span className="CauseNoticeBold">{props.t('CauseImportantNotice')}</span>{props.t('CauseImportantNoticeText')}</div>
        </div>
    )
}

Vision.propTypes = {
    toTab: PropTypes.func,
    t: PropTypes.func
}

export default Vision
