import React from 'react'
import PropTypes from 'prop-types'
import './JoinUs.css';
import { Form } from "./Form";



function JoinUs(props) {

    return (
        <div className="join-us-container">
            <div className="join-us-title">{props.t('JoinUsTitle')}</div>
            <div className="join-us-subtitle">
                <div>{props.t('JoinUsSubTitle1')}</div>
                <div>{props.t('JoinUsSubTitle2')}</div>
            </div>
            <Form {...props} />
        </div>
    )
}

JoinUs.propTypes = {
    t: PropTypes.func
}

export default JoinUs

