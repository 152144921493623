import React ,{useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';
import { Redirect } from 'react-router';
import { Header } from './components/Header'
import './App.css';
import { Home } from "./screens/Home";
import { About } from "./screens/About";
import { ContactUs } from "./screens/ContactUs";
import { JoinUs } from "./screens/JoinUs";
import { Footer } from "./components/Footer";
import { Donations } from "./screens/Donations";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import HttpsRedirect from 'react-https-redirect';


function App({t}) {
  const [history, setHistory] =useState([window.location.pathname]);
  const [redirectTo, setRedirectTo] = useState(window.location.pathname);
  const [currentLang, setCurrentLang] = useState('he');


  const trackingId = "G-4LM8V6W87J"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  const historyGA = createBrowserHistory();

  // Initialize google analytics page view tracking
  historyGA.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLang(lng)
  }

  function handleOnClick(redirectTo){
  setRedirectTo(redirectTo);
  setHistory([...history, redirectTo])
  window.scrollTo(0, 0)
  }

  window.onpopstate = e => {
    history.pop()
    let currentPage = history.pop();
    setRedirectTo(currentPage);
    setHistory([...history, currentPage])
  }


 
  return (
    <HttpsRedirect>
    <Router history={historyGA}>
    <div className={t("MainCSS")}>
    <div className='App'>
      <div className='header' >
      <Header t={t} handleOnClick={handleOnClick} redirectTo={redirectTo} currentLang={currentLang} changeLanguage={changeLanguage} />
      </div>
    
      <div className='Content'>
        <Redirect push to={redirectTo} />
        <Switch>
          <Route path="/about">
            <About t={t} toTab={handleOnClick} />
          </Route>
          <Route path="/contact-us">
            <ContactUs t={t} toTab={handleOnClick} />
          </Route>
          <Route path="/join-us">
            <JoinUs t={t} toTab={handleOnClick} />
          </Route>
          <Route path="/donations">
            <Donations t={t} toTab={handleOnClick} />
          </Route>
          <Route path="/">
            <Home t={t} toTab={handleOnClick} />
          </Route>
        </Switch>
      </div>
    
    <div className='footer' >
    <Footer t={t} handleOnClick={handleOnClick} />
    </div>
    </div>
    </div>
    </Router>
    </HttpsRedirect>
  );
}

export default withNamespaces()(App);
