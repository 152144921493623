import React from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as LogoSvg} from '../../Images/logo_black.svg';
import './Footer.css'

function Footer(props) {
    return (
        <div className="footer-container">
            <div className="footer-contact">
                <h6 className="footer-contact-title" onClick={() =>  props.handleOnClick("/contact-us")} >{props.t('ContactUs')}</h6>
                <div className="footer-contact-email-container">
                    <a className="footer-contact-email-text" href="mailto:office@ohelsarah.co.il" data-type="mail" data-content="office@ohelsarah.co.il" data-auto-recognition="true">{props.t('Email')}</a>
                    <div className="footer-contact-phone-text">{props.t('PhoneNum')}: 072-2462072</div>
                </div>
            </div>
            <div className="footer-info">
                <div className="footer-info-start">
                    <h4 className="footer-info-start-title">
                        {props.t('LongName')}
                    </h4>
                    <h4 className="footer-info-start-text">
                        {props.t('OfficialNum')}
                    </h4>
                </div>
                <div className="footer-info-middle">
                    <div className="footer-info-logo-container" >
                        <LogoSvg className="footer-info-logo" />
                    </div>
                </div>
                <div className="footer-info-end">
                    <h1 className="footer-info-end-text">
                        {props.t('InMemory')}
                    </h1>
                    <h1 className="footer-info-end-text">
                        {props.t('Sara')}
                    </h1>
                    <h1 className="footer-info-end-text">
                        {props.t('Chaim')}
                    </h1>
                </div>
            </div>
            <div className="footer-empty">
                <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            </div>
        </div>
    )
}

Footer.propTypes = {
    handleOnClick: PropTypes.func,
    t: PropTypes.func
}

export default Footer

