import React from 'react'
import PropTypes from 'prop-types'
import './About.css'
import Image from '../../Images/about.jpg';
import {YTView} from '../../components/YouTube/'

function About(props) {
    return (
        <div className="about-container">
            <div className="about-title">{props.t('AboutTitle')}</div>
            <div className="about-video-container">
                <YTView embedId={props.t('embedId')} />
            </div>
            <div className="about-text">
                <div className="about-bottom-padding">{props.t('AboutTextGeneral')}</div>
                <div className="about-bottom-padding">
                    <div className="bold-text">{props.t('AboutTextHowTitle')}</div>
                    <div>{props.t('AboutTextHow1')}</div>
                    <div>{props.t('AboutTextHow2')}</div>
                    <div>{props.t('AboutTextHow3')}</div>
                    <div>{props.t('AboutTextHow4')}</div>
                </div>
                <div className="about-bottom-padding">
                    <div className="bold-text">{props.t('AboutTextElseTitle')}</div>
                    <div>{props.t('AboutTextElse')}</div>
                </div>
            </div>
            <div className="image-container">
                <img src={Image} alt="Girl holding Light" width="100%" height="100%" />
            </div>
            <div className="about-text-why">
                <div className="bold-text about-bottom-padding">{props.t('AboutTextWhyTitle')}</div>
                <div className="about-bottom-padding">
                    <div>{props.t('AboutTextWhy1')}</div>
                    <div>{props.t('AboutTextWhy2')}</div>
                    <div>{props.t('AboutTextWhy3')}</div>
                </div>
                <div className="about-bottom-padding">
                    <div>{props.t('AboutTextWhySara')}</div>
                    <div>{props.t('AboutTextWhyChaim')}</div>
                </div>
            </div>
        </div>
    )
}

About.propTypes = {
    t: PropTypes.func
}

export default About

