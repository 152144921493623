import React from 'react';
import { Opening } from './Opening';
import { Vision } from './Vision';
import { More } from "./More";
import './Home.css';

function Home(props) {
    return (
        <div className="home-container">
            <div className="opening">
                <Opening {...props} />
            </div>
            <div className="vision">
                <Vision {...props} />
            </div>
            <div className="more">
                <More {...props} />
            </div>
        </div>
    )
}

export default Home