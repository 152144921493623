import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './Form.css'
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  container: {
    width: 'min(454px, 80%)',
    flexDirection: 'column',
    display: 'inline-flex',
    paddingBottom: 'min(30px, 3%)'
  },
  textField: {
    width: 200,
    margin: '8px',
  },
  textField2: {
    width: '90%',
    margin: '8px',
  },
  flex: {
    display: 'flex'
  },
  submit: {
  },
}));

function ContactUs(props) {

    const classes = useStyles();
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    subject: '',
    position: '',
    phoneNumber: '',
    additionalInfo: '',
  });
   const [errors, setErros] = React.useState({
    name: true,
    email: true,
    position: true
  });
  const [wasSent,setWasSent] = React.useState(false);

  const defaultValues = () => {
    setValues({
        name: '',
        email: '',
        subject: '',
        position: '',
        phoneNumber: '',
        additionalInfo: ''
    });
    setErros({
        name: true,
        email: true,
        position: true
    });
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    if((name !== 'subject') && (name !== 'phoneNumber') && (name !== 'additionalInfo')){
        if (event.target.value === '' || event.target.value === undefined){
            setErros({ ...errors, [name]: true });
        }else{
            setErros({ ...errors, [name]: false });
        }
    };
    setWasSent(false);
  };

  const sendEmail = () => {
      const url='https://ohel-app.azurewebsites.net/api/join_us-post';
      const data = values;
      axios({
        method: 'post',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        },
        url:url,
        data: data}
      ).then(setWasSent(true)).catch(err=>console.log(err));
      defaultValues();
  }

    return (
        <div className="form-container">
            <form className={classes.container} noValidate autoComplete="off">
            <div className={classes.flex}>
      <TextField
        id="standard-required"
        placeholder={props.t('Name')}
        className={classes.textField}
        value={values.name}
        onChange={handleChange('name')}
        margin="normal"
        error={values.name === ''}
      />
      <TextField
        id="standard-required"
        placeholder={props.t('ContacterEmail')}
        className={classes.textField}
        value={values.email}
        onChange={handleChange('email')}
        margin="normal"
        error={values.email === ''}
      />
      </div>
      <TextField
        id="standard-normal"
        placeholder={props.t('Job')}
        className={classes.textField2}
        value={values.position}
        onChange={handleChange('position')}
        margin="normal"
        error={values.position === ''}
      />
      <TextField
        id="standard-normal"
        placeholder={props.t('Subject')}
        className={classes.textField2}
        value={values.subject}
        onChange={handleChange('subject')}
        margin="normal"
      />
      <TextField
        id="standard-normal"
        placeholder={props.t('PhoneNum')}
        className={classes.textField2}
        value={values.phoneNumber}
        onChange={handleChange('phoneNumber')}
        margin="normal"
      />
      <TextField
        id="standard-multiline-static"
        placeholder={props.t('MoreInfo')}
        className={classes.textField2}
        value={values.additionalInfo}
        multiline
        onChange={handleChange('additionalInfo')}
        margin="normal"
        rows={6}
      />
    </form>
    <div className="send-button">
    <Button disabled={errors.name || errors.email || errors.position} color="secondary" onClick={sendEmail}>{props.t('Send')}</Button>
    {wasSent && <div className="form-sent">{props.t('Sent')}</div>}
        </div>
         </div>
    )
}

ContactUs.propTypes = {
    t: PropTypes.func
}

export default ContactUs

