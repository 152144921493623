import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as JGiveSvg} from '../../Images/JGive.svg';
import {ReactComponent as ILSSvg} from '../../Images/ils.svg';
import {ReactComponent as USDSvg} from '../../Images/usd.svg';
import './Donations.css';

function Donations(props) {
    const [isIls, setIsIls] = useState(true);
    return (
        <div className="donations-container">
        <div className="donations-icon-container">
            <div className="donations-icons">
                {!isIls && 
                <>
                <div className="donations-country">
                    <ILSSvg onClick={()=> setIsIls(true)} />
                </div>
                <div>{props.t('ilsText')}</div>
                </>}
                {isIls && 
                <>
                <div className="donations-country">
                    <USDSvg onClick={()=> window.open("https://web.causematch.com/donate/ohelsarah", "_blank")} />
                </div>
                <div>{props.t('usdText')}</div>
                </>}
            </div>
        </div>
            <div className="donations-title">
                <div className="donations-title-text">
                    <div>{props.t('DonationText')}</div>
                    <div>{props.t('DonationText2')}</div>
                    <div>{props.t('DonationCCOption')}</div>
                </div>
            </div>
            <div className="donations-iframe">
                <iframe src={isIls ? "https://meshulam.co.il/purchase?b=2961201e4dbf0129210160ec4a5107ec" : "https://web.causematch.com/donate/ohelsarah"} title="Donations Pay Page" height="100%" width="100%" />
            </div>
            <div className="donations-footer">
                <div className="donations-footer-text">
                    <div>{props.t('DonationContact')}</div>
                    <a className="donations-footer-email-text" href="mailto:Donate@ohelsarah.co.il" data-type="mail" data-content="Donate@ohelsarah.co.il" data-auto-recognition="true">{props.t('DonationEmail')}</a>
                </div>
            </div>
        </div>
    )
}

Donations.propTypes = {
    t: PropTypes.func
}

export default Donations

