import React from 'react'
import './Opening.css'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: 'all 0.2s ease, visibility 0s',
    borderRadius: '0px',
    border: '5px double #173B34',
    color: '#FFFFFF',
    backgroundColor: 'rgba(65, 85, 92, 0.2)',
    letterSpacing: '0.05em',
    fontSize: '23px',
    fontFamily: 'almoni-dl-aaa-700,sans-serif',
    width: '270px',
    height: '55px',
    '&:hover': {
        backgroundColor: 'rgba(65, 85, 92, 1)'
    }
  },
  buttonJoin: {
    transition: 'all 0.2s ease, visibility 0s',
    borderRadius: '0px',
    border: '5px double #173B34',
    color: '#FFFFFF',
    backgroundColor: 'rgba(65, 85, 92, 0.2)',
    letterSpacing: '0em',
    fontSize: '17px',
    fontFamily: 'almoni-dl-aaa-700,sans-serif',
    width: '270px',
    height: '55px',
    '&:hover': {
        backgroundColor: 'rgba(65, 85, 92, 1)'
    }
  },
  '@media only screen and (max-width:1140px)': {
    button: {
        transition: 'all 0.2s ease, visibility 0s',
        borderRadius: '0px',
        border: '5px double #173B34',
        color: '#FFFFFF',
        backgroundColor: 'rgba(65, 85, 92, 1)',
        letterSpacing: '0.05em',
        fontSize: '23px',
        fontFamily: 'almoni-dl-aaa-700,sans-serif',
        width: '200px',
        height: '55px',
        margin: '10px',
        '&:lang(en)': {
            fontSize: '18px'
        }
    },
  buttonJoin: {
    transition: 'all 0.2s ease, visibility 0s',
    borderRadius: '0px',
    border: '5px double #173B34',
    color: '#FFFFFF',
    backgroundColor: 'rgba(65, 85, 92, 1)',
    letterSpacing: '0em',
    fontSize: '17px',
    fontFamily: 'almoni-dl-aaa-700,sans-serif',
    width: '270px',
    height: '55px',
    margin: '10px'
  },
  }
}));

function Opening(props) {
    const classes = useStyles();

    return (
        <div className="background">
            <div>
                <div className={props.t('MainCSS')==='AppHe' ? "org-name" : "org-name-en"}>{props.t('OrgName')}</div>
                <div className={props.t('MainCSS')==='AppHe' ? "title" : "title-en"}>{props.t('HomeScreenMainTitle')}</div>
                <div className="sub-title">{props.t('HomeScreenSubTitle')}</div>
                <div className="buttons">
                    <Button
                        variant="contained"
                        onClick={()=> props.toTab('/donations')}
                        className={classes.button}
                        startIcon={<FavoriteIcon className="icon" />}
                    >
                        <div className="text" >{props.t('HomeScreenDonateButton')}</div>
                    </Button>
                    <div className="space" />
                    <Button
                        variant="contained"
                        onClick={()=> props.toTab('/join-us')}
                        className={classes.buttonJoin}
                    >
                        <div className="text" >{props.t('HomeScreenProfessionalsButton')}</div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

Opening.propTypes = {
    toTab: PropTypes.func,
    t: PropTypes.func
}

export default Opening