import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import {ReactComponent as LogoSvg} from '../../Images/logo-white_filled.svg';
import {ReactComponent as USSvg} from '../../Images/US-flag.svg';
import {ReactComponent as ILSvg} from '../../Images/IL-flag.svg';
import MenuIcon from '@material-ui/icons/Menu';
import Slide from '@material-ui/core/Slide';
import ReactGA from 'react-ga';
import './Header.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    direction: 'rtl'
  },
  he: {
    direction: 'rtl'
  },
  en: {
    direction: 'ltr'
  },
  background: {
    backgroundColor: 'rgba(37, 65, 81, 1)',
    height: 'fit-content',
    maxHeight: '140px',
    paddingTop: '.75%',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    borderTop: '0px solid rgba(140, 140, 139, 1)',
    borderBottom: '0px solid rgba(140, 140, 139, 1)',
    position: 'fixed'
  },
  tabButton: {
    color: '#FFFFFF',
    font: 'normal normal normal min(27px,3vw)/1.4em almoni-dl-aaa-700,sans-serif',
    textAlign: 'center',
    display: 'inline-block',
    padding: '0 min(10px,1%)',
    transition: 'color 0.4s ease 0s',
    '&:hover': {
        color: '#D55342'
    },
    '&:focus': {
        outline: 'none'
    }
  },
  tabButtonChosen: {
    color: '#D55342',
    font: 'normal normal normal min(27px,3vw)/1.4em almoni-dl-aaa-700,sans-serif',
    textAlign: 'center',
    display: 'inline-block',
    padding: '0 min(10px,1%)',
    transition: 'color 0.4s ease 0s',
    '&:focus': {
        outline: 'none'
    }
  },

  navBar: {
    visibility: 'visible',
    display: 'inherit',
    position: 'inherit',
    width: '100%',
    height: '100%'
  },
  menuButton: {
    visibility: 'hidden',
    display: 'none',
    position: 'fixed',
    width: '0px',
    height: '0px'
  },
  dialogContainer: {
    backgroundColor: 'rgba(37, 65, 81, 1)',
    width: '100vw',
    height: '100vh',
    display: "flex",
    flexDirection: "column",
    paddingTop: "50px",
    paddingBottom: "60px"
  },
  NavContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1'
  },
  dialogLng: {
    alignSelf: 'center',
    paddingTop: '100px'
  },
  dialogMenuButton: {
    color: 'white',
    '&:focus': {
        outline: 'none'
    }
  },
  dialogMenuButtonContainer: {
    alignSelf: 'flex-end',
    paddingRight: '10px',
    paddingBottom: '20px'
  },
  dialogTabButton: {
    color: '#FFFFFF',
    font: 'normal normal normal 33px/1.4em almoni-dl-aaa-700,sans-serif',
    textAlign: 'center',
    display: 'inline-block',
    padding: '0 min(10px,1%)',
    transition: 'color 0.4s ease 0s',
    '&:hover': {
        color: '#D55342'
    },
    '&:focus': {
        outline: 'none'
    }
  },
  dialogTabButtonChosen: {
    color: '#D55342',
    font: 'normal normal normal 33px/1.4em almoni-dl-aaa-700,sans-serif',
    textAlign: 'center',
    display: 'inline-block',
    padding: '0 min(10px,1%)',
    transition: 'color 0.4s ease 0s',
    '&:focus': {
        outline: 'none'
    }
  },
  langLogo :{
    maxWidth: '100%',
    maxHeight: '56px'
  },
  langContainer : {
    marginRight: '10px',
    marginTop: '0px'
  },
  flagContainer : {
    maxWidth: '56px',
    maxHeight: '56px'

  },
    donationButtonContainer: {
      position: 'inherit',
      marginLeft: '0px'
    },
  donationButton: {
    backgroundColor: 'rgba(248, 228, 164, 1)',
    border: 'solid rgba(199, 247, 216, 1) 0px',
    cursor: 'pointer !important',
    font: 'normal normal normal 27px/1.4em almoni-dl-aaa-700,sans-serif',
    transition: 'color 0.4s ease 0s',
    color: '#000000',
    display: 'inline-block',
    width: 'min(179px,10vw)',
    height: '50%',
    '&:hover': {
        backgroundColor: 'rgba(199, 247, 216, 1)'
    },
    '&:focus': {
        outline: 'none'
    }
  },
  logo :{
    width: '100%',
    height: '100%',
    '&:focus': {
        outline: 'none'
    }
  },
  logoName: {
    display: 'flex',
    flexDirection: 'column',
    '&:focus': {
        outline: 'none'
    },
    maxWidth: '200px',
    maxHeight: '125px'
  },
    logoNameMobile: {
    visibility: 'hidden',
    display: 'none',
    position: 'fixed',
    '&:focus': {
        outline: 'none'
    }
  },
  title: {
    flexGrow: 1,
  },
  mobileFlagMenu: {
    position: 'inherit',
    marginRight: '0px'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center'
  },

  '@media only screen and (max-width:1140px)': {
    title: {
      flexGrow: 0
    },
    root: {
      flexGrow: 1,
      direction: 'ltr'
    },
    background: {
      backgroundColor: 'rgba(37, 65, 81, 1)',
      height: 'fit-content',
      minHeight: '80px',
      paddingTop: '.75%',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      borderTop: '0px solid rgba(140, 140, 139, 1)',
      borderBottom: '0px solid rgba(140, 140, 139, 1)',
      position: 'absolute'
    },
    donationButtonContainer: {
      position: 'absolute',
      left: '0',
      marginLeft: '10px'
    },
    donationButton: {
    backgroundColor: 'rgba(248, 228, 164, 1)',
    border: 'solid rgba(199, 247, 216, 1) 0px',
    cursor: 'pointer !important',
    font: 'normal normal normal max(13px,2.8vw)/1.4em almoni-dl-aaa-700,sans-serif',
    transition: 'color 0.4s ease 0s',
    color: '#000000',
    display: 'inline-block',
    width: 'fit-content',
    height: '50%',
    '&:hover': {
        backgroundColor: 'rgba(199, 247, 216, 1)'
    },
    '&:focus': {
        outline: 'none'
    }
    },
  logoName: {
    visibility: 'hidden',
    display: 'none',
    position: 'fixed',
    width: '0px',
    height: '0px',
    flexDirection: 'column',
    '&:focus': {
        outline: 'none'
    }
  },
    logoNameMobile: {
    visibility: 'visible',
    display: 'flex',
    position: 'inherit',
    justifyContent: 'center',
    maxWidth: '160px',
    maxHeight: '100px',
    '&:focus': {
        outline: 'none'
    }
  },
  langContainer : {
    marginRight: '0px',
    marginTop: '15px'
  },
  navBar: {
      visibility: 'hidden',
      display: 'none',
      position: 'fixed',
      width: '0px',
      height: '0px'
    },
  menuButton: {
    visibility: 'visible',
    display: 'inherit',
    backgroundColor: 'rgba(37, 65, 81, 1)',
    '&:focus': {
        outline: 'none'
    },
    position: 'fixed',
    marginLeft: '8px',
    top: '4px',
    width: '45px',
    height: '45px'
  },
  menuButtonIcon: {
    color: 'white',
    '&:focus': {
        outline: 'none'
    },
  },
    flagContainer : {
    maxWidth: '40px',
    maxHeight: '40px'

  },
  mobileFlagMenu: {
    position: 'absolute',
    right: '0',
    marginRight: '10px'
  }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Header(props) {
  const currentPage = props.redirectTo
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDonationClick = () => {
    ReactGA.event({
      category: 'Page Click',
      action: 'Donate from header'
    });
    props.handleOnClick("/donations")
  };
    
    return (
        <div className={classes.root}>
      <AppBar position="static"  className={classes.background}>
        <Toolbar className={classes.toolbar}>
          <div>
            <div className={classes.logoName}>
                  <LogoSvg className={classes.logo} onClick={() => props.handleOnClick("/")} disableFocusRipple={true} />
            </div>
          </div>
          <Typography variant="h6" className={classes.title}>
          <div className={classes.navBar}>
        <nav className={props.currentLang==='he'? classes.he : classes.en}>
              <Button className={(currentPage==="/") ? classes.tabButtonChosen : classes.tabButton} onClick={() => props.handleOnClick("/")} >{props.t('Home')}</Button>
              <Button className={(currentPage==="/donations") ? classes.tabButtonChosen : classes.tabButton} onClick={() =>  props.handleOnClick("/donations")} >{props.t('Donations')}</Button>
              <Button className={(currentPage==="/join-us") ? classes.tabButtonChosen : classes.tabButton} onClick={() =>  props.handleOnClick("/join-us")} >{props.t('JoinUs')}</Button>
              <Button className={(currentPage==="/about") ? classes.tabButtonChosen : classes.tabButton} onClick={() =>  props.handleOnClick("/about")} >{props.t('About')}</Button>
              <Button className={(currentPage==="/contact-us") ? classes.tabButtonChosen : classes.tabButton} onClick={() =>  props.handleOnClick("/contact-us")} >{props.t('ContactUs')}</Button>
        </nav>
        </div>
         
          </Typography>
          <div className={classes.donationButtonContainer}>
          <Button className={classes.donationButton} color="inherit" onClick={handleDonationClick}>{props.t('ToDonate')}</Button>
          </div>
          <div  className={classes.logoNameMobile}>
                  <LogoSvg className={classes.logo}  onClick={() => props.handleOnClick("/")} disableFocusRipple={true} />
            </div>
          <div className={classes.mobileFlagMenu}>
          <div  className={classes.langContainer}>
          <div  className={classes.flagContainer}>
          {props.currentLang==='en'? 
              <ILSvg className={classes.langLogo}  color="inherit" onClick={() =>  props.changeLanguage("he")} disableFocusRipple={true} />
                :
              <USSvg className={classes.langLogo}  color="inherit" onClick={() =>  props.changeLanguage("en")} disableFocusRipple={true} />
          }
          </div>
          </div>
           <div className={classes.menuButton}>
        <IconButton className={classes.menuButtonIcon} onClick={handleClickOpen} disableFocusRipple={true}>
          <MenuIcon/>
        </IconButton>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <div className={classes.dialogContainer}>
          <div className={classes.dialogMenuButtonContainer}>
            <IconButton className={classes.dialogMenuButton}  onClick={handleClose} disableFocusRipple={true}>
              <MenuIcon/>
            </IconButton>
          </div>
          <nav className={classes.NavContainer}>
              <Button className={(currentPage==="/") ? classes.dialogTabButtonChosen : classes.dialogTabButton} onClick={() => {props.handleOnClick("/"); handleClose()}} >{props.t('Home')}</Button>
              <Button className={(currentPage==="/donations") ? classes.dialogTabButtonChosen : classes.dialogTabButton} onClick={() =>  {props.handleOnClick("/donations"); handleClose()}} >{props.t('Donations')}</Button>
              <Button className={(currentPage==="/join-us") ? classes.dialogTabButtonChosen : classes.dialogTabButton} onClick={() =>  {props.handleOnClick("/join-us"); handleClose()}} >{props.t('JoinUs')}</Button>
              <Button className={(currentPage==="/about") ? classes.dialogTabButtonChosen : classes.dialogTabButton} onClick={() =>  {props.handleOnClick("/about"); handleClose()}} >{props.t('About')}</Button>
              <Button className={(currentPage==="/contact-us") ? classes.dialogTabButtonChosen : classes.dialogTabButton} onClick={() =>  {props.handleOnClick("/contact-us"); handleClose()}} >{props.t('ContactUs')}</Button>
          </nav>
          <div className={classes.dialogLng}>
          <div  className={classes.flagContainer}>
          {props.currentLang==='en'? 
              <ILSvg className={classes.langLogo} color="inherit" onClick={() =>  props.changeLanguage("he")} disableFocusRipple={true} />
              :
              <USSvg className={classes.langLogo} color="inherit" onClick={() =>  props.changeLanguage("en")} disableFocusRipple={true} />
          }
          </div>
          </div>
          </div>
        </Dialog>
      </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
    )
}

Header.propTypes = {
    handleOnClick: PropTypes.func,
    t: PropTypes.func,
    redirectTo: PropTypes.string,
    currentLang: PropTypes.string,
    changeLanguage: PropTypes.func
}

export default Header

