import React from 'react';
import PropTypes from 'prop-types';
import './More.css';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {ReactComponent as JoinSvg} from '../../../Images/help-blue.svg';
import {ReactComponent as ContactSvg} from '../../../Images/customer-service-blue.svg';
import {ReactComponent as DonateSvg} from '../../../Images/box-blue.svg';

const useStyles = makeStyles((theme) => ({
    moreIcon: {
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'contents',
    '&:focus': {
        outline: 'none'
    }
    }
}));

function More(props) {

    const classes = useStyles();

    return (
        <div className="more-container" >
            <div className="more-box" >
                <div className="more-icon">
                    <IconButton className={classes.moreIcon} onClick={()=> props.toTab('/join-us')}>
                        <JoinSvg className="icon-container" />
                    </IconButton>
                </div>
                <div className="more-title" onClick={()=> props.toTab('/join-us')}>
                    {props.t('RecommendationsTitle')}
                </div>
                <div className="more-text" >
                    <div>{props.t('RecommendationsText1')}</div>
                    <div>{props.t('RecommendationsText2')}</div>
                    <div>{props.t('RecommendationsText3')}</div>
                </div>
            </div>
            <div className="more-box" >
                <div className="more-icon">
                    <IconButton className={classes.moreIcon} onClick={()=> props.toTab('/donations')}>
                        <DonateSvg className="icon-container" />
                    </IconButton>
                </div>
                <div className="more-title" onClick={()=> props.toTab('/donations')}>
                    {props.t('DonationsTitle')}
                </div>
                <div className="more-text" >
                    <div>{props.t('DonationsTextStart')}</div>
                    <div>{props.t('DonationsTextBank')}</div>
                    <div>{props.t('DonationsTextOrCC')}</div>
                </div>
            </div>
            <div className="more-box" >
                <div className="more-icon">
                    <IconButton className={classes.moreIcon} onClick={()=> props.toTab('/contact-us')}>
                        <ContactSvg className="icon-container" />
                    </IconButton>
                </div>
                <div className="more-title" onClick={()=> props.toTab('/contact-us')}>
                    {props.t('ContactUsTitle')}
                </div>
                <div className="more-text" >
                    <a className="more-text" href="mailto:office@ohelsarah.co.il" data-type="mail" data-content="office@ohelsarah.co.il" data-auto-recognition="true">{props.t('Email')}</a>
                    <div className="more-text">{props.t('PhoneNum')}: 072-2462072</div>
                </div>
            </div>
        </div>
    )
}

More.propTypes = {
    toTab: PropTypes.func,
    t: PropTypes.func
}

export default More

